<template>
  <div @click="hideAllDropdown">
    <BreadCrumb title="Activity Log" subtitle=""></BreadCrumb>
    <div class="d-flex align-items-center left mt-3" @click="goBack">
        <span class="material-symbols-rounded me-2">arrow_back</span>
        <p class="mb-0">Back</p>
      </div>

    <TableList
      class="table-margin"
      v-if="tableLoading || tableData.length > 0 || $route.query.statement"
      :loading="tableLoading"
      :tableConfig="tableConfig"
      :tableData="tableData"
      :actions="tableHandler"
      @sort-data="sortData"
      @send-query="sendQuery"
      :hideCheckbox="true"
    />

    <div v-else class="empty-placeholder">
      <span class="min-margin"></span>
      <img src="@/assets/misc/empty.svg" alt="No Content"/>
      <p class="m-0">No Activity Yet!</p>
      <p>Your activity log will display a history of your interactions on Nexlaw.</p>
    </div>

    <!-- Delete Modal -->
    <b-modal
      id="delete-modal"
      hide-header
      hide-footer
      centered
    >
      <div class="delete-prompt">
        <img src="@/assets/misc/delete.svg" alt="Delete" />
        <p>
          <b>Are you sure you want to delete this campaign?</b><br>
          This action is permanent and cannot be undone.
        </p>
      </div>
      <div class="delete-prompt-options">
        <button class="btn-outlined" @click="$bvModal.hide('delete-modal')">
          Cancel
        </button>
        <button class="btn-danger" @click="deleteCampaign">Delete</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BreadCrumb from '../../components/misc/BreadCrumb.vue';
import TableList from '../../components/misc/TableList.vue';
import general from '../../store/general';

export default {
  components: {
    BreadCrumb,
    TableList,
  },

  data() { return {
    // header
    creds: {},

    // Case Flow Results
    tableLoading: true,
    tableConfig: {
      format: [
        {
          header: "Date",
          width: 20,
          button: true
        },
        {
          header: "Feature",
          width: 20,
          button: true
        },
        {
          header: "Activity",
          width: 40,
        },
        {
          header: "Created by",
          width: 20,
        },
      ],
      options: [
        {
          icon: "unarchive",
          label: "Undo Archive",
        },
        {
          icon: "delete",
          label: "Delete Campaign",
        },
      ],
      pagination: {
        current: 1,
        total: 0,
      },
    },
    fullData: [],
    tableData: [],

    // Dropdown Menu
    selected: null,
  }},

  watch: {
    // update route when changing page
    tableConfig: {
      handler(newCf) {
        const page = newCf.pagination.current;
        if (this.$route.query.page == page) return;

        const query = { page };
        if (this.$route.query.statement) query.statement = this.$route.query.statement;
        this.$router.push({ query });
      },
      deep: true,
    },

    // update data when changed, works when using backward navigation
    $route() { this.loadData(); }
  },

  methods: {
    hideAllDropdown() { this.$root.$emit('bv::hide::tooltip'); },

    sortData(data) { this.tableData = data; },

    tableHandler(o, id) {
      this.selected = this.tableData[id][3];
      const campaignId = this.tableData[id][0];

      switch(o) {
        // View File
        // case 0: 
        //   this.viewCampaign(campaignId);
        //   break;

        case 0:
           this.unarchiveCampaign(campaignId); 
           break;
        // case 2:
        //   this.archiveCampaign();
        //   break;

        // Delete
        case 1: this.$bvModal.show("delete-modal"); break;
      }
    },
    viewCampaign(campaignId) {
      this.$router.push({
        name: "LegalCopilot_CaseFlowResult",
        params: { id: campaignId }
      });
    },
    unarchiveCampaign(campaignId){
    general
        .UnarchiveCampaign(campaignId)
        .then(res => {
            if (res.data.message === "Successful") this.$toast.success(res.data.message);
            this.loadData();
        });
    },
    archiveCampaign() {
      general
        .ArchiveCampaign(this.selected)
        .then(res => {
          if (res.data.message === "Successful") this.$toast.success(res.data.message);
          this.loadData();
        });
    },

    deleteCampaign() {
      this.$bvModal.hide("delete-modal");
      general
        .DeleteCampaign(this.selected)
        .then(res => {
          if (res.data.message === "Successful") this.$toast.success(res.data.message);
          this.loadData();
        });
    },

    getCampaignInfo(entry) {
      switch (entry.type) {
        case "les": return entry.client_case;

        case "argument": case "memo":
          return entry.statement;

        case "case_summarizer": case "contract_review": return entry.file_name;

        case "statement": case "legislation":
          return entry.statement
            .replaceAll(`["`, ``)
            .replaceAll(`"]`, ``);

        case "LOD":
          if (entry.letter_type == "custom") return entry.info.prompt;
          return this.$options.filters.legalWriter_composeType(entry.compose_type);

        case "chatbot":
          return entry.title;
      }
    },

    loadData() {
      const statement = this.$route.query.statement ?? null;
      const page = this.$route.query.page;

      const obj = { 
        types: [
          'case_summarizer',
          'argument',
          'memo',
          'contract_review',
          'legislation',
          'les',
          'chatbot',
          'nexa_document_insights',
          'nexa_deep_research',
          'nexa_compare_jurisdictions',
          'nexa_find_precedents',
          'nexa_analyze_pleadings',
          'nexa_build_argument',
          'nexa_build_memo',
          'nexa_due_diligence',
          'nexa_build_contract',
          'nexa_summarizer'
        ], page };
      if (statement) obj.statement = statement;

      this.tableLoading = true;
      general
        .ActivityLog(obj)
        .then(res => {
          if (!res) return;
          const DATA = res.data.data;

          this.tableConfig.pagination = {
            current: DATA.current_page,
            total: DATA.total,
            perPage: DATA.per_page,
          };
          this.fullData = DATA.data;
          this.statLoading = false;
          this.tableLoading = false;

          this.tableData = this.fullData.map(ent => [
            ent.created_at,
            this.$options.filters.feature(ent.type),
            this.getCampaignInfo(ent),
            this.$options.filters.username(ent.creator.name), 
          ]);
        })
    },

    sendQuery(q) {
      const Rquery = this.$route.query;
      const query = { page: 1 };
      if (q) query.statement = q;
      else if (Rquery.page > 1 || Rquery.statement) this.$router.push({ query });
    },
    goBack() {
      this.$router.push({ name: 'Archieve' });
    },
    navigate(to) {
      if (to === "+") {
        this.$router.push({ name: "LegalCopilot_PreTrial" });
      } else {
        return;
      }
    },
  },

  created() {
    const CRED = JSON.parse(localStorage.getItem("userInfo")).nexlaw_credits;
    this.creds.les = CRED.full.les - CRED.used.les;

    if (!this.$route.query.page) this.$router.push({ query: { page: 1 } });
    else this.loadData();
  },
}
</script>

<style scoped>
.table-margin {
  margin: 16px 10px 0px 10px;
}

.btn-primary {
  height: 40px;

  span {
    margin-right: 10px;
  }
}

.empty-placeholder {
  min-height: 40%;
  width: 500px;

  .min-margin {
    display: inline-block;
    height: 110px;
  }
  
  img {
    margin-top: auto;
    margin-bottom: 24px;
  }

  p {
    text-align: center;
    margin-bottom: 32px;
  }
}
</style>

<!-- Delete Modal -->
<style scoped>
.delete-prompt {
  margin: 8px 8px 20px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-prompt img {
  background-color: var(--interface-grey);
  padding: 32px;
  border-radius: 8px;
  height: 164px;
  width: 100%;
  margin-bottom: 16px;
}

.delete-prompt p {
  margin-bottom: 0px;
}

.delete-prompt p b {
  font-weight: 500;
  font-size: 16px;
  color: var(--neutral-black);
}

.delete-prompt-options {
  display: flex;
  justify-content: space-between;
  margin: 0px 8px 8px 8px;
}

.delete-prompt-options button {
  width: calc(50% - 4px);
  height: 32px;
}
.left{
    color: var(--primary);
    cursor: pointer;
}
#Header{
    background: #fff;
}
</style>